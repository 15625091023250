<template>
  <v-dialog v-model="isLoading" max-width="100" persistent content-class="hidden-shadow">
    <v-card color="transparent" flat tile>
      <v-card-text>
        <center>
          <v-progress-circular indeterminate color="accent" :size="50"></v-progress-circular>
        </center>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadingDialog',
  props: {
    isLoading: Boolean
  }
}
</script>

